<script setup lang="ts">
export type InputDynamicType =
  | "text"
  | "toggle"
  | "switch"
  | "explorer"
  | "explorer-multiply"
  | "textarea"
  | "number"
  | "wusiwyg"
  | "select-menu"
  | "date-picker"
  | "email";

const props = withDefaults(
  defineProps<{
    type?: InputDynamicType;
    label?: string;
    placeholder?: string;
  }>(),
  {
    type: "text",
    placeholder: "",
  },
);
</script>

<template>
  <USwitch
    v-if="props.type === 'toggle' || props.type === 'switch'"
    color="success"
    :ui="{ description: 'hidden' }"
  />
  <ExplorerInput
    v-else-if="props.type === 'explorer' || props.type === 'explorer-multiply'"
  />
  <UTextarea
    v-else-if="props.type === 'textarea'"
    class="w-full"
    autoresize
    :placeholder="props.placeholder ? props.placeholder : props.label"
  />
  <Editor v-else-if="props.type === 'wusiwyg'" />
  <USelectMenu
    v-else-if="props.type === 'select-menu'"
    :placeholder="props.placeholder ? props.placeholder : props.label"
  />
  <InputDatePicker v-else-if="props.type === 'date-picker'" />
  <UInput
    v-else
    class="w-full"
    :placeholder="props.placeholder ? props.placeholder : props.label"
  />
</template>

<style scoped></style>
