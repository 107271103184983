<script setup lang="ts">
import type { File as FileEntity } from "~/domain/entity";
import { getS3Path } from "~/utils/s3";
import { IconName } from "~/utils/enum/icon_name";

const props = defineProps<{
  file: FileEntity;
}>();
</script>

<template>
  <div class="preview-container">
    <div v-if="props.file.isImage" class="cover w-50">
      <Image
        class="w-full h-full object-cover"
        :src="getS3Path(props.file)"
        :alt="props.file.title"
        loading="lazy"
      />
    </div>
    <div v-else class="flex items-center gap-3">
      <div class="w-5 h-5">
        <UIcon class="w-full h-full object-cover" :name="IconName.File" />
      </div>
      <div class="font-medium">
        {{ props.file.title }}.{{ props.file.extension }}
      </div>
    </div>
  </div>
</template>

<style scoped></style>
