<script setup lang="ts">
import { useFileApi } from "~/composables/api/file";
import { getS3Path } from "~/utils/s3";
import { Editor, EditorContent } from "@tiptap/vue-3";
import Image from "@tiptap/extension-image";
import Blockquote from "@tiptap/extension-blockquote";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Bold from "@tiptap/extension-bold";
import Code from "@tiptap/extension-code";
import Highlight from "@tiptap/extension-highlight";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import DropcursorOptions from "@tiptap/extension-dropcursor";
import HardBreak from "@tiptap/extension-hard-break";
import History from "@tiptap/extension-history";
import Document from "@tiptap/extension-document";
import Gapcursor from "@tiptap/extension-gapcursor";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";

import css from "highlight.js/lib/languages/css";
import js from "highlight.js/lib/languages/javascript";
import ts from "highlight.js/lib/languages/typescript";
import html from "highlight.js/lib/languages/xml";

// load all languages with "all" or common languages with "common"
import { all, createLowlight } from "lowlight";
import {
  ImagePlaceholder,
  ImageUpload,
} from "~/components/editor/custom-extensions/image";
import { IconName } from "~/utils/enum/icon_name";
import { useZap } from "~/composables/logger/zap";
import { createErrorToast } from "~/utils/notification/error";
import { SpecialId } from "~/utils/enum/special_id";

// create a lowlight instance
const lowlight = createLowlight(all);

// you can also register languages
lowlight.register("html", html);
lowlight.register("css", css);
lowlight.register("js", js);
lowlight.register("ts", ts);

const fileApi = useFileApi();

const model = defineModel<string>();
const linkModel = ref("");

const content = ref<string>("");

const l = useZap("editor");

const is = ref({
  showUpdateLinkModal: false,
});

async function uploadImage(file: File, id: string): Promise<string> {
  const _l = l.named("uploadImage");

  try {
    const formData = new FormData();
    formData.append(id, file);

    const res = await fileApi.uploadFileViaAdmin({
      file: file,
      folderId: SpecialId.AutoUploadedFilesFolderId,
    });

    return getS3Path(res.data.payload.file);
  } catch (e) {
    createErrorToast();
    await _l.error(e);
    return "";
  }
}

const editor = new Editor({
  content: model.value,
  extensions: [
    History,
    DropcursorOptions,
    HardBreak,
    Image,
    Blockquote,
    Paragraph,
    Text,
    BulletList,
    OrderedList,
    ListItem,
    Heading.configure({
      levels: [1, 2, 3, 4, 5, 6],
    }),
    HorizontalRule,
    Bold,
    Code,
    Highlight,
    Italic,
    Link.configure({
      HTMLAttributes: {
        target: "_blank",
      },
    }),
    Strike,
    Underline,
    Document,
    Gapcursor,
    // TODO надо добавить параметры обработки в api
    // ImageUpload.configure({
    //   acceptMimes: ["image/jpeg", "image/png", "image/jpg"],
    //   upload: uploadImage,
    // }),
    // ImagePlaceholder.configure({
    //   inline: false,
    // }),
    CodeBlockLowlight.configure({
      lowlight,
    }),
    Table,
    TableRow,
    TableHeader,
    TableCell,
  ],
  onUpdate({ editor }) {
    model.value = editor.getHTML();
  },
});

function updateLink() {
  linkModel.value = editor.getAttributes("link").href;
  is.value.showUpdateLinkModal = true;
}

function onConfirmUpdateLink() {
  if (!linkModel.value) {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    return;
  }

  editor
    .chain()
    .focus()
    .extendMarkRange("link")
    .setLink({ href: linkModel.value, target: "_blank" })
    .run();
}

watch(
  () => model.value,
  (value) => {
    if (!value) {
      editor.commands.clearContent();
    }
  },
);

onBeforeUnmount(() => {
  if (!editor) return;
  editor.destroy();
});
</script>

<template>
  <div @click.stop.prevent>
    <div v-if="editor" class="flex items-start justify-between gap-3">
      <ModalPrompt
        v-model="is.showUpdateLinkModal"
        v-model:input="linkModel"
        title="Ссылка"
        @confirm="onConfirmUpdateLink"
      />

      <div>
        <div class="flex flex-col gap-3 mi:flex-row">
          <div class="tools-row">
            <UButton
              :icon="IconName.Bold"
              :variant="editor.isActive('bold') ? 'solid' : 'outline'"
              size="sm"
              :disabled="!editor.can().chain().focus().toggleBold().run()"
              @click="editor.chain().focus().toggleBold().run()"
            />
            <UButton
              :icon="IconName.Italic"
              :variant="editor.isActive('italic') ? 'solid' : 'outline'"
              size="sm"
              :disabled="!editor.can().chain().focus().toggleItalic().run()"
              @click="editor.chain().focus().toggleItalic().run()"
            />
            <UButton
              :icon="IconName.Strike"
              :variant="editor.isActive('strike') ? 'solid' : 'outline'"
              size="sm"
              :disabled="!editor.can().chain().focus().toggleStrike().run()"
              @click="editor.chain().focus().toggleStrike().run()"
            />
            <UButton
              :icon="IconName.Link"
              :variant="editor.isActive('link') ? 'solid' : 'outline'"
              size="sm"
              @click="updateLink"
            />
          </div>

          <div class="tools-row">
            <UButton
              :icon="IconName.BulletList"
              variant="outline"
              size="sm"
              @click="editor.chain().focus().toggleBulletList().run()"
            />
            <UButton
              :icon="IconName.OrderedList"
              variant="outline"
              size="sm"
              @click="editor.chain().focus().toggleOrderedList().run()"
            />
            <UButton
              :icon="
                editor.can().sinkListItem('listItem')
                  ? IconName.QueueList
                  : IconName.QueueListSolid
              "
              :variant="'outline'"
              size="sm"
              @click="
                editor.can().sinkListItem('listItem')
                  ? editor.chain().focus().sinkListItem('listItem').run()
                  : editor.chain().focus().liftListItem('listItem').run()
              "
            />

            <div class="tools-row" />
          </div>
        </div>

        <div class="flex flex-wrap gap-1 mt-3">
          <UButton
            variant="outline"
            label="Вставить таблицу"
            @click="
              editor
                .chain()
                .focus()
                .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                .run()
            "
          />

          <template v-if="editor.can().chain().focus().deleteTable().run()">
            <UButton
              variant="outline"
              label="Удалить таблицу"
              :disabled="!editor.can().chain().focus().deleteTable().run()"
              @click="editor.chain().focus().deleteTable().run()"
            />
            <UButton
              variant="outline"
              label="Колонка до"
              :disabled="!editor.can().chain().focus().deleteTable().run()"
              @click="editor.chain().focus().addColumnBefore().run()"
            />
            <UButton
              variant="outline"
              label="Колонка после"
              :disabled="!editor.can().chain().focus().deleteTable().run()"
              @click="editor.chain().focus().addColumnAfter().run()"
            />
            <UButton
              variant="outline"
              label="Удалить колонку"
              :disabled="!editor.can().chain().focus().deleteTable().run()"
              @click="editor.chain().focus().deleteColumn().run()"
            />
            <UButton
              variant="outline"
              label="Ряд до"
              :disabled="!editor.can().chain().focus().deleteTable().run()"
              @click="editor.chain().focus().addRowBefore().run()"
            />
            <UButton
              variant="outline"
              label="Ряд после"
              :disabled="!editor.can().chain().focus().deleteTable().run()"
              @click="editor.chain().focus().addRowAfter().run()"
            />
            <UButton
              variant="outline"
              label="Удалить ряд"
              :disabled="!editor.can().chain().focus().deleteTable().run()"
              @click="editor.chain().focus().deleteRow().run()"
            />
            <UButton
              variant="outline"
              label="Объединить/Разъединить"
              :disabled="!editor.can().chain().focus().mergeOrSplit().run()"
              @click="editor.chain().focus().mergeOrSplit().run()"
            />
          </template>
        </div>
      </div>

      <div class="tools-row">
        <UButton
          :icon="IconName.Undo"
          size="sm"
          color="neutral"
          variant="subtle"
          :disabled="!editor.can().chain().focus().undo().run()"
          @click="editor.chain().focus().undo().run()"
        />
        <UButton
          :icon="IconName.Redo"
          color="neutral"
          variant="subtle"
          size="sm"
          :disabled="!editor.can().chain().focus().redo().run()"
          @click="editor.chain().focus().redo().run()"
        />
      </div>
    </div>
    <div
      class="mt-3 max-w-full max-h-[300px] p-1 md:max-h-[500px] overflow-auto"
    >
      <EditorContent v-model="content" class="g-live-html" :editor="editor" />
    </div>
  </div>
</template>

<style scoped>
.tools-row {
  display: flex;
  align-items: center;
  gap: 4px;
}

:deep(.tiptap) {
  overflow: auto;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  color: #111827;
  background: var(--ui-bg);
  border: 1px solid var(--ui-border-accented);

  .selectedCell:after {
    background: rgba(0, 123, 239, 0.44);
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 1;
  }
}
</style>
