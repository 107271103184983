import { AxiosWrapper } from "~/utils/axios-wrapper";
import {
  createAuthHeader,
  createReqUpdateAuth,
} from "~/utils/http/axios/interceptors";
import type {
  CreateFolderViaAdminRequest,
  CreateFolderViaAdminResponse,
} from "~/composables/api/dto/create_folder";
import type {
  DeleteFolderByIdViaAdminRequest,
  DeleteFolderByIdViaAdminResponse,
} from "~/composables/api/dto/delete_folder";
import type {
  GetFolderItemListViaAdminRequest,
  GetFolderItemListViaAdminResponse,
} from "~/composables/api/dto/get_folder_item_list";
import { sanitizeApiData } from "~/utils/object";

class FolderApi extends AxiosWrapper {
  constructor(apiBaseUrl: string) {
    super({
      apiEndpoint: apiBaseUrl,
      config: {
        withCredentials: true,
      },
      interceptors: {
        request: {
          fulfilled: [createAuthHeader],
        },
        response: {
          rejected: [createReqUpdateAuth],
        },
      },
    });
  }

  createFolderViaAdmin(
    data: CreateFolderViaAdminRequest,
  ): CreateFolderViaAdminResponse {
    const sData = sanitizeApiData(data);
    return this.post(`${this.apiEndpoint}/api/v1/admin/folder`, sData);
  }

  deleteFolderViaAdmin(
    data: DeleteFolderByIdViaAdminRequest,
  ): DeleteFolderByIdViaAdminResponse {
    return this.delete(
      `${this.apiEndpoint}/api/v1/admin/folder/${data.folderId}`,
    );
  }

  getFolderItemListViaAdmin(
    data?: GetFolderItemListViaAdminRequest,
  ): GetFolderItemListViaAdminResponse {
    if (!data || !data?.folderId) {
      data = {
        folderId: undefined,
      };
    }

    return this.get(
      `${this.apiEndpoint}/api/v1/admin/folder/${data?.folderId}`,
    );
  }
}

export const useFolderApi = createSharedComposable(() => {
  const { NUXT_API_BASE_URL } = useRuntimeConfig().public;

  return new FolderApi(NUXT_API_BASE_URL);
});
