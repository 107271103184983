<script setup lang="ts">
import {
  type ExplorerFilter,
  useAdminExplorerStore,
} from "~/stores/admin/explorer";
import type { File as FileEntity } from "~/domain/entity";
import { useFileApi } from "~/composables/api/file";
import { useZap, newApiErr } from "~/composables/logger/zap";
import { dynamicToast } from "~/utils/notification/error";
import { isInvalidResponse } from "~/utils/http/response";
import { Variant } from "~/utils/enum/variant";

export type ExplorerInputProps = {
  filter?: ExplorerFilter;
};

export type ExplorerInputModelItem = {
  fileId: string;
  sortOrder: number;
};

export type ExplorerInputModel = undefined | string | ExplorerInputModelItem[];

type FileItem = {
  file: FileEntity;
  sortOrder: number;
};

const props = defineProps<ExplorerInputProps>();

const model = defineModel<ExplorerInputModel>();

const { $bus } = useNuxtApp();
const adminExplorerStore = useAdminExplorerStore();
const fileApi = useFileApi();
let eventName = "";
const file = ref<FileEntity | null | undefined>(undefined);
const fileList = ref<FileItem[]>([]);
const modelIsArray = ref(false);
const l = useZap("ExplorerInput");

const is = reactive({
  fetching: false,
  confirmDeleteModal: false,
});

const fileItemDeletingCandidate = ref<FileItem | FileEntity | null | undefined>(
  undefined,
);

function showManager(fileForReplacement?: FileItem) {
  const { filter } = props;
  eventName = adminExplorerStore.open(filter);

  // @ts-expect-error
  $bus.on(eventName, (f: FileEntity) => {
    if (modelIsArray.value) {
      if (fileForReplacement) {
        const idx = fileList.value.findIndex(
          (fi) => fi.file.id === fileForReplacement.file.id,
        );

        // @ts-expect-error
        fileList.value[idx].file = f;

        // @ts-expect-error
        (model.value as ExplorerInputModelItem[])[idx].fileId = f.id;

        return;
      }

      const len = fileList.value.length;

      fileList.value.push({
        file: f,
        sortOrder: len,
      });

      (model.value as ExplorerInputModelItem[]).push({
        fileId: f.id,
        sortOrder: len,
      });

      return;
    }

    file.value = f;
    model.value = f.id;
  });
}

function deleteFromList(f: FileItem) {
  if (modelIsArray.value) {
    const idx = fileList.value.findIndex((fi) => fi.file.id === f.file.id);
    fileList.value.splice(idx, 1);
    (model.value as ExplorerInputModelItem[]).splice(idx, 1);

    return;
  }
}

function toggleConfirmDeleteModal(
  f?: FileItem | FileEntity | null | undefined,
  forceValue?: boolean,
) {
  fileItemDeletingCandidate.value = f;

  if (forceValue !== undefined) {
    is.confirmDeleteModal = forceValue;
  } else {
    is.confirmDeleteModal = !is.confirmDeleteModal;
  }
}

function deleteConfirmed() {
  if (!fileItemDeletingCandidate.value) return;

  if (modelIsArray.value) {
    deleteFromList(fileItemDeletingCandidate.value as FileItem);
  } else {
    file.value = undefined;
    model.value = undefined;
  }

  is.confirmDeleteModal = false;
  fileItemDeletingCandidate.value = undefined;
}

async function loadFile(fileId: string): Promise<FileEntity | null> {
  if (is.fetching) return null;

  const _l = l.named("init");
  let err: any;
  let file: FileEntity | null = null;

  try {
    is.fetching = true;

    const res = await fileApi.getFileByIdViaAdmin({
      fileId: fileId,
    });

    if (isInvalidResponse(res)) {
      err = res.data.error;
      throw newApiErr("isInvalidResponse", res);
    }

    if (!res.data.payload.file) {
      throw new Error("File is empty");
    }

    file = res.data.payload.file;
  } catch (e) {
    dynamicToast(err);
    _l.error(e);
  } finally {
    is.fetching = false;
  }

  return file;
}

async function init() {
  const _l = l.named("init");

  if (!model.value) return;

  if (Array.isArray(model.value)) {
    modelIsArray.value = true;

    for (const item of model.value) {
      if (!item.fileId) {
        _l.error("fileId is not provided");
        continue;
      }

      const f = await loadFile(item.fileId);

      if (f) {
        fileList.value.push({
          file: f,
          sortOrder: item.sortOrder,
        });
      }
    }

    return;
  }

  file.value = await loadFile(model.value);
}

function updateFileListSortOrder(idx: number, sortOrder?: string | number) {
  if (!modelIsArray.value) return;

  if (!sortOrder) {
    return;
  }

  const newSortOrder = parseInt(sortOrder as string, 10);

  if (isNaN(newSortOrder) || newSortOrder < 0) {
    dynamicToast("Неправильный порядок сортировки");
    return;
  }

  fileList.value.forEach((fi, idx) => {
    (model.value as ExplorerInputModelItem[])[idx] = {
      fileId: fi.file.id,
      sortOrder: fi.sortOrder,
    };
  });
}

// const getSortedFileList = computed(() => {
//   if (!fileList.value.length) return [];
//
//   const f = [...fileList.value];
//
//   return f.sort((a, b) => a.sortOrder - b.sortOrder);
// });

onMounted(async () => {
  init();
});

onBeforeUnmount(() => {
  // @ts-expect-error
  $bus.off(eventName);
});

watch(
  () => model.value,
  (value) => {
    if (modelIsArray.value && (value as []).length === 0) {
      if (is.fetching) return;

      is.fetching = true;

      // Костыль нужен, чтобы избежать ошибки рекурсивного обновления реактивного компонента
      setTimeout(() => {
        fileList.value = [];
      }, 50);

      is.fetching = false;

      return;
    }

    if (value) return;

    file.value = null;
  },
  {
    deep: true,
  },
);

function onChange(idx: number, sortOrder?: string | number) {
  updateFileListSortOrder(idx, sortOrder);
}
</script>

<template>
  <div>
    <UButton v-if="is.fetching" loading />
    <template v-else>
      <ExplorerConfirmDelete
        v-model="is.confirmDeleteModal"
        @confirm="deleteConfirmed"
        @cancel="toggleConfirmDeleteModal(undefined, false)"
      />

      <div v-if="modelIsArray">
        <UButton
          v-if="!fileList.length"
          label="Выбрать файл"
          color="neutral"
          variant="subtle"
          @click="showManager()"
        />
        <template v-else>
          <UCard v-for="(f, idx) in fileList" :key="idx" class="last:mb-0 mb-6">
            <template #header>
              <UFormField label="Порядок">
                <UInput
                  v-model="f.sortOrder"
                  type="text"
                  @change="onChange(idx, f.sortOrder)"
                />
              </UFormField>
            </template>
            <ExplorerInputPreview :file="f.file" />
            <template #footer>
              <div class="flex items-center gap-3">
                <UButton
                  label="Заменить"
                  color="info"
                  @click="showManager(f)"
                />
                <UButton
                  label="Удалить"
                  :variant="Variant.Ghost"
                  color="error"
                  variant="subtle"
                  @click="toggleConfirmDeleteModal(f)"
                />
              </div>
            </template>
          </UCard>
          <UCard>
            <UButton
              label="Добавить файл"
              color="success"
              @click="showManager()"
            />
          </UCard>
        </template>
      </div>
      <template v-else>
        <div class="flex items-center gap-3">
          <UButton
            label="Выбрать файл"
            color="neutral"
            variant="subtle"
            @click="showManager()"
          />

          <UButton
            v-if="file"
            label="Удалить"
            :variant="Variant.Ghost"
            color="error"
            variant="subtle"
            @click="toggleConfirmDeleteModal(file)"
          />
        </div>

        <ExplorerInputPreview v-if="file" :file="file" class="mt-6" />
      </template>
    </template>
  </div>
</template>

<style scoped></style>
