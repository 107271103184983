<script setup lang="ts">
import { DatePicker } from "v-calendar";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

const model = defineModel<Date | number | string | null | undefined>();
</script>

<template>
  <div>
    <p class="mb-3 text-sm">
      {{ model ? format(model, "d MMM, yyy", { locale: ru }) : "Не выбрано" }}
    </p>
    <DatePicker v-model="model" />
  </div>
</template>

<style scoped></style>
