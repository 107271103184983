<script setup lang="ts">
const emits = defineEmits<{
  (e: "confirm"): void;
  (e: "cancel"): void;
}>();

function confirm() {
  emits("confirm");
}

function cancel() {
  emits("cancel");
}
</script>

<template>
  <ModalConfirm
    title="Подтвердите удаление"
    confirm-button-label="Удалить"
    :confirm-button-props="{ color: 'error' }"
    @confirm="confirm"
    @reject="cancel"
  />
</template>

<style scoped></style>
