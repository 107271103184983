<script setup lang="ts">
import type { NuxtUiButtonUi } from "~/types/nuxt/ui/button";
import type { FormField } from "~/components/input/types";

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;

    confirmButtonLabel?: string;
    rejectButtonLabel?: string;

    confirmButtonProps?: NuxtUiButtonUi;
    rejectButtonProps?: NuxtUiButtonUi;

    inputProps?: FormField;
  }>(),
  {
    title: "Ввод",
    description: "Введите значение",

    confirmButtonLabel: "Подтвердить",
    rejectButtonLabel: "Отмена",

    confirmButtonProps: {
      color: "blue",
    },
    rejectButtonProps: {
      color: "blue",
      variant: "ghost",
    },

    inputProps: () => ({
      name: "input",
      autocomplete: "off",
      size: "xl",
      required: false,
    }),
  },
);

const emits = defineEmits<{
  (e: "confirm"): void;
  (e: "reject"): void;
}>();

const showModalModel = defineModel<boolean>({ required: true });
const inputModel = defineModel<any>("input", { required: true });

function onConfirm() {
  showModalModel.value = false;
  emits("confirm");
}

function onReject() {
  showModalModel.value = false;
  emits("reject");
}
</script>

<template>
  <Modal v-model="showModalModel" :title="props.title" @close="onReject">
    <template #default>
      <div>{{ props.description }}</div>
      <UForm
        :state="{ [props.inputProps.name]: inputModel }"
        сlass="mt-3"
        @submit="onConfirm"
      >
        <UFormField v-bind="props.inputProps" class="mt-2">
          <InputDynamic v-model="inputModel" v-bind="props.inputProps" />
        </UFormField>
      </UForm>
    </template>

    <template #footer>
      <UButton
        :label="props.rejectButtonLabel"
        v-bind="props.rejectButtonProps"
        color="info"
        @click="onReject"
      />
      <UButton
        :label="props.confirmButtonLabel"
        v-bind="props.confirmButtonProps"
        color="success"
        @click="onConfirm"
      />
    </template>
  </Modal>
</template>

<style scoped></style>
