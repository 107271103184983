import { useFolderApi } from "~/composables/api/folder";
import { useZap, newInvalidResponseError } from "~/composables/logger/zap";
import type { File as FileEntity, Folder } from "~/domain/entity";
import { isInvalidResponse } from "~/utils/http/response";
import { createErrorToast, dynamicToast } from "~/utils/notification/error";
import { getLatestItemInArray } from "~/utils/array";
import { deepCopy } from "~/utils/object";
import { useFileApi } from "~/composables/api/file";
import { BusEvent } from "~/utils/enum/bus_event";
import { genUuid } from "~/utils/random";

export type ExplorerFilter = {
  onlyImage?: boolean;
  excludeFileIdList?: string[];
};

export const useAdminExplorerStore = defineStore("adminExplorer", () => {
  const l = useZap("adminExplorerStore");
  const { $bus } = useNuxtApp();
  const folderApi = useFolderApi();
  const fileApi = useFileApi();

  function getDefaultIsState() {
    return {
      creatingFolder: false,

      loadingFolderItemList: false,
      loadingCreateFolder: false,
      loadingDeleteFolder: false,
      loadingUploadFile: false,
      loadingDeleteFile: false,
    };
  }

  const is = ref(getDefaultIsState());

  function getDefaultCurrentFolder(): Folder {
    return {
      id: "",
      createdAt: new Date(),
      updatedAt: new Date(),
      isActive: true,
      isActiveUpdatedAt: new Date(),
      title: "Корневая папка",
      parentFolderId: undefined,
    };
  }

  const fileSelectedEventName = ref("");
  const isActive = ref(false);
  const filter = ref<ExplorerFilter | undefined>(undefined);
  const selectedFile = ref<FileEntity | undefined>(undefined);
  const currentFolder = ref<Folder>(getDefaultCurrentFolder());
  const folderList = ref<Folder[]>([]);
  const fileList = ref<FileEntity[]>([]);
  const folderHistory = ref<Folder[]>([currentFolder.value]);

  function resetState() {
    is.value = getDefaultIsState();
    fileSelectedEventName.value = "";
    isActive.value = false;
    filter.value = undefined;
    selectedFile.value = undefined;
    currentFolder.value = getDefaultCurrentFolder();
    folderList.value = [];
    fileList.value = [];
    folderHistory.value = [currentFolder.value];
  }

  const publicGetters = {
    getIsActive: computed(() => isActive.value),

    getFilter: computed(() => filter.value),

    getIsLoadingFolderItemList: computed(() => is.value.loadingFolderItemList),
    getIsLoadingCreateFolder: computed(() => is.value.loadingCreateFolder),
    getIsLoadingDeleteFolder: computed(() => is.value.loadingDeleteFolder),
    getIsLoadingUploadFile: computed(() => is.value.loadingUploadFile),
    getIsLoadingDeleteFile: computed(() => is.value.loadingDeleteFile),

    getSelectedFile: computed(() => selectedFile.value),

    getFolderList: computed(() => folderList.value),
    getFileList: computed(() => fileList.value),
    getIsEmptyItemList: computed(
      () => folderList.value.length === 0 && fileList.value.length === 0,
    ),
    getIsBothListNotEmpty: computed(
      () => folderList.value.length > 0 && fileList.value.length > 0,
    ),

    getNotImages: computed(() =>
      fileList.value.filter((file) => !file.isImage),
    ),
    getImages: computed(() => fileList.value.filter((file) => file.isImage)),

    getCurrentFolder: computed(() => currentFolder.value),

    getIsCreatingFolder: computed(() => is.value.creatingFolder),

    getFolderHistoryBreadcrumbs: computed((): string =>
      folderHistory.value.map((folder) => folder.title).join(" / "),
    ),

    getFolderHistoryIsNotEmpty: computed(() => folderHistory.value.length > 1),
  };

  const publicActions = {
    open(explorerFilter?: ExplorerFilter): string {
      filter.value = explorerFilter;
      isActive.value = true;
      fileSelectedEventName.value = `${BusEvent.FileSelected}:${genUuid()}`;

      return fileSelectedEventName.value;
    },

    setIsActive: function (value: boolean) {
      isActive.value = value;

      if (!value) {
        resetState();
      }
    },

    setIsCreatingFolder: function (value: boolean) {
      is.value.creatingFolder = value;
    },

    loadFolderItemList: async function (folder?: Folder) {
      if (is.value.loadingFolderItemList) return;

      const _l = l.named("loadFolderItemList");

      try {
        is.value.loadingFolderItemList = true;

        folderList.value = [];
        fileList.value = [];

        const res = await folderApi.getFolderItemListViaAdmin({
          folderId: folder?.id,
        });

        if (isInvalidResponse(res)) {
          throw newInvalidResponseError(res);
        }

        const apiFolderList = res.data.payload.folderList;
        const apiFileList = res.data.payload.fileList;

        folderList.value = apiFolderList ? (apiFolderList as Folder[]) : [];
        fileList.value = apiFileList ? (apiFileList as FileEntity[]) : [];

        if (folder) {
          const existingItem = folderHistory.value.find(
            (f) => f.id === folder.id,
          );

          if (!existingItem) {
            folderHistory.value.push(folder);
          }

          currentFolder.value = deepCopy(folder);
        } else {
          currentFolder.value = getDefaultCurrentFolder();
          folderHistory.value = [currentFolder.value];
        }
      } catch (e) {
        createErrorToast();
        await _l.error(e);
      } finally {
        is.value.loadingFolderItemList = false;
      }
    },

    createFolder: async function (title: string) {
      if (is.value.loadingCreateFolder) return;

      const _l = l.named("createFolder");

      try {
        is.value.loadingCreateFolder = true;

        const res = await folderApi.createFolderViaAdmin({
          title: title,
          parentFolderId: currentFolder.value.id,
        });

        if (isInvalidResponse(res)) {
          throw newInvalidResponseError(res);
        }

        is.value.creatingFolder = false;

        await this.loadFolderItemList(currentFolder.value);
      } catch (e) {
        createErrorToast();
        await _l.error(e);
      } finally {
        is.value.loadingCreateFolder = false;
      }
    },

    back: async function () {
      if (folderHistory.value.length === 0) {
        return;
      }

      folderHistory.value.pop();
      const latestItem = getLatestItemInArray(folderHistory.value);

      await this.loadFolderItemList(latestItem);
    },

    deleteFolder: async function (folderId: string) {
      if (is.value.loadingDeleteFolder) return;

      const _l = l.named("deleteFolder");
      let err: any;

      try {
        is.value.loadingDeleteFolder = true;

        const res = await folderApi.deleteFolderViaAdmin({
          folderId: folderId,
        });

        if (isInvalidResponse(res)) {
          err = res.data.error;
          throw newInvalidResponseError(res);
        }

        await this.loadFolderItemList(currentFolder.value);
      } catch (e) {
        dynamicToast(err);
        await _l.error(e);
      } finally {
        is.value.loadingDeleteFolder = false;
      }
    },

    uploadFile: async function (file: File) {
      if (is.value.loadingUploadFile) return;

      const _l = l.named("uploadFile");
      let err: any;

      try {
        is.value.loadingUploadFile = true;

        const res = await fileApi.uploadFileViaAdmin({
          folderId: currentFolder.value.id,
          file: file,
        });

        if (isInvalidResponse(res)) {
          err = res.data.error;
          throw newInvalidResponseError(res);
        }

        await this.loadFolderItemList(currentFolder.value);
      } catch (e) {
        dynamicToast(err);
        await _l.error(e);
      } finally {
        is.value.loadingUploadFile = false;
      }
    },

    deleteFile: async function (fileId: string) {
      if (is.value.loadingDeleteFile) return;

      const _l = l.named("deleteFile");
      let err: any;

      try {
        is.value.loadingDeleteFile = true;

        const res = await fileApi.deleteFileViaAdmin({
          fileId: fileId,
        });

        if (isInvalidResponse(res)) {
          err = res.data.error;
          throw newInvalidResponseError(res);
        }

        await this.loadFolderItemList(currentFolder.value);
      } catch (e) {
        dynamicToast(err);
        await _l.error(e);
      } finally {
        is.value.loadingDeleteFile = false;
      }
    },

    selectFile: function (file: FileEntity) {
      selectedFile.value = file;

      $bus.emit(fileSelectedEventName.value, file);
    },
  };

  return {
    ...publicGetters,
    ...publicActions,

    isActive,
    is,
  };
});
